/* eslint-disable react/display-name */
import { DatePicker } from '@mantine/dates'
import { TextField } from '@mui/material'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import { Dayjs } from 'dayjs'
import { isDate, isString } from 'lodash'
import { memo } from 'react'
import { LiteralUnion } from 'type-fest'
import { proxy, ref, useSnapshot } from 'valtio'
import { component } from '~/utils/component'
import dayAPI from '~/utils/dayAPI'

export class FuiDatePicker {
  store

  constructor() {
    this.store = proxy({
      date: dayAPI(),
    })
  }

  setDate(date: Date | Dayjs | string) {
    this.store.date = isDate(date) ? dayAPI(date) : isString(date) ? dayAPI(date) : date
  }

  formatBy(
    /**
     * - 若給 `/` 則返回 `2022/12/22`（預設）
     * - 若給 `-` 則返回 `2022-12-22`
     */
    divider?: LiteralUnion<'/' | '-', string>,
  ) {
    divider = divider || `/`

    return this.store.date.format(`YYYY${divider}MM${divider}DD`)
  }

  DatePickerOfMt = ref(
    component<
      ReactProps<{
        /** 預設 `-30` 日 */
        minDate?: Dayjs
        /** 預設今日 */
        maxDate?: Dayjs
      }>
    >(props => {
      const state = useSnapshot(this.store)
      const minDate = props.minDate || dayAPI().subtract(30, 'day')
      const maxDate = props.maxDate || dayAPI()

      return (
        <DatePicker
          className={props.className}
          fullWidth
          locale='zh-tw'
          closeCalendarOnChange
          inputFormat='YYYY/MM/DD'
          labelFormat='YYYY/MM'
          defaultValue={state.date.toDate()}
          onChange={value => {
            value && this.setDate(value)
          }}
          minDate={minDate.toDate()}
          maxDate={maxDate.toDate()}
        ></DatePicker>
      )
    }),
  )

  DatePickerOfMui = ref(
    memo<
      ReactProps<{
        /** 預設 `-30` 日 */
        minDate?: Dayjs
        /** 預設今日 */
        maxDate?: Dayjs
      }>
    >(props => {
      const state = useSnapshot(this.store)
      const minDate = props.minDate || dayAPI().subtract(30, 'day')
      const maxDate = props.maxDate || dayAPI()

      return (
        <MuiDatePicker
          className={props.className}
          {...{
            disableToolbar: true,
            fullWidth: true,
            variant: 'inline',
            inputVariant: 'outlined',
            size: 'small',
            format: 'YYYY/MM/DD',
            InputProps: {
              style: {
                height: 30,
              },
            },
          }}
          renderInput={params => <TextField {...params} />}
          value={state.date}
          onChange={(value: Dayjs | null) => {
            if (value) {
              this.store.date = value
            }
          }}
          minDate={minDate}
          maxDate={maxDate}
        />
      )
    }),
  )
}
